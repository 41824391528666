/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { RequestUtils } from '@broadleaf/admin-components/dist/metadata';

interface AccountCommissionRecipient {
  email: string;
}

type Response = {
  getFetchAccountCommissionRecipients: (
    contextParams
  ) => Promise<Array<AccountCommissionRecipient>>;
};

export default function useFetchAccountCommissionRecipients(): Response {
  const getFetchAccountCommissionRecipients = async (
    contextParams
  ): Promise<Array<AccountCommissionRecipient>> => {
    contextParams;
    let response = null;
    try {
      response = await RequestUtils.get(
        {
          headers: {},
          mapParams: [],
          method: 'GET',
          scope: 'ACCOUNT_COMMISSION',
          uri: '/customer/account-commission-recipients'
        },
        contextParams
      );
    } catch (e) {
      console.log(e);
    }

    if (!response || !response.data || response.data.numberOfElements < 1) {
      return [];
    }

    return response.data.content;
  };

  return { getFetchAccountCommissionRecipients };
}
