/*
Copyright (C) 2018 Broadleaf Commerce

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { IFulfillmentContextParams } from '@broadleaf/admin-components/dist/types/oms';
import { ContextParameters } from '@broadleaf/admin-components/dist/tracking/utils/ContextRequest';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';

/**
 * Returns the context parameters for the current order view.
 *
 * <p>
 * If the `state` is provided, this will include the `state` as a context parameter,
 * as well as place the fulfillment data at the `parent`. This allows downstream
 * actions and other components to more easily reference order properties in
 * their urls and parameters.
 *
 * @param {Object} metadata the view metadata
 * @param [state] the current fulfillment state, if provided
 *
 * @returns {IFulfillmentContextParams}
 */
export default function useRmApplicationContextParams(
  metadata: IMetadata,
  state: IFulfillmentViewLocalState = undefined
): IFulfillmentContextParams {
  const { id } = useParams() as Record<string, any>;
  const tracking = useTracking(metadata);
  return useMemo(() => {
    const contextParams = {} as IFulfillmentContextParams;
    if (id) {
      contextParams.id = id;
    }

    if (tracking) {
      contextParams[ContextParameters.TRACKING] = tracking;
      if (!tracking.applicationId) {
        contextParams[ContextParameters.TRACKING].applicationId = '2';
        contextParams[ContextParameters.TRACKING].customerContextId = '2';
      }
    }

    if (state && state.data) {
      contextParams.parent = contextParams.fulfillment = state.data;
      contextParams.fulfillmentId = state.data.id;
      contextParams.orderId = state.data.orderId;
    }

    return contextParams;
  }, [id, state, tracking]);
}
