/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC, useCallback, useState } from 'react';
import { includes } from 'lodash';

import log from '@broadleaf/admin-components/dist/common/utils/log';
import { getEndpointByType } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import FulfillmentAction from '@broadleaf/admin-components/dist/oms/components/FulfillmentAction';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import cx from 'classnames';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { showUpdateTracking } from '../../util/ShipmentUtils';

const logger = log.getLogger(
  'oms.components.FulfillmentView.action-components.UpdateTracking'
);

export interface UpdateTrackingProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const UpdateTracking: FC<UpdateTrackingProps> = props => {
  const { actionDefinition, dispatch, metadata, state } = props;
  const { attributes = {}, label } = actionDefinition;
  const { allowedStatuses = [] } = attributes as Record<string, any>;

  const trackingEndpoint = getEndpointByType(
    actionDefinition,
    'UPDATE_TRACKING'
  );

  const contextParams = useContextParams(metadata, state);
  const [message, setMessage] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const updateTracking = useCallback(async () => {
    try {
      setLoading(true);
      const response = await request(
        { method: 'post', ...trackingEndpoint },
        contextParams
      );
      let message = 'Tracking info updated, ';
      if (response.data.nextStatus) {
        message +=
          'the fulfillment status was updated to ' + response.data.nextStatus;
      } else {
        message += 'the fulfillment status did not change';
      }
      setMessage({ text: message });
    } catch (err) {
      if (err.cancelled) {
        return;
      }
      let message = 'Error updating tracking';
      if (err?.response?.data?.type === 'EntityNotFoundException') {
        message =
          'Shipping label has not been purchased, try printing the label';
      }
      setMessage({
        className: 'text-danger',
        text: message
      });
      logger.error(
        `An error occurred trying to update tracking for fulfillment ${contextParams.id}`
      );
    } finally {
      setLoading(false);
      setTimeout(() => {
        setMessage({});
        dispatch(queueFetch());
      }, 4000);
    }
  }, [contextParams, dispatch, state.data]);

  if (
    !includes(allowedStatuses, state.data.status) ||
    !showUpdateTracking(state.data)
  ) {
    return <></>;
  }

  return (
    <>
      <FulfillmentAction
        disabled={state.isFetching || loading}
        onClick={updateTracking}
      >
        {label}
      </FulfillmentAction>
      {message && (
        <span
          className={cx(
            message.className,
            'text-success tw-text-center tw-text-xs tw-font-bold'
          )}
        >
          {message.text}
        </span>
      )}
    </>
  );
};

export default UpdateTracking;
export { UpdateTracking };
