/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import ActionListGrid from '@broadleaf/admin-components/dist/collection/components/ActionListGrid';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';
import ActionRegistrar from '@broadleaf/admin-components/dist/metadata/services/ActionRegistrar';
import useInvoicesGrid from '../../hooks/useInvoicesGrid';
import { getFetchInvoicesEndpoint } from '../../utils/InvoicesOperationUtils';

import messages from './HeldInvoicesGrid.messages';
import type { ICommonGridHeader } from '@broadleaf/admin-components/dist/types/common';
import actionComponents from './action-components';
import { getDefaultInvoiceHeaders } from '../../utils/InvoicesGridUtils';

export const HeldInvoicesGrid: HeldInvoicesGridDefinition = () => {
  const { collectionState, getActionListGridProps } = useInvoicesGrid({
    readConfig: getFetchInvoicesEndpoint(),
    //use this grid to show invoices that are held for review or have a credit pending
    invoiceStatuses: ['HOLD_FOR_REVIEW', 'REQUIRES_PO', 'CREDIT_PENDING']
  });

  const actionListGridProps = getActionListGridProps();
  const actionComponents = ActionRegistrar.getActionComponents(
    'View',
    HeldInvoicesGrid.VIEW_TYPE
  );
  const actionDefinitions = useHeldInvoicesActionDefinitions();
  const headers = useInvoiceHistoryHeaders();
  const label = useLabel();
  return (
    <ActionListGrid
      augmentationKey={''}
      {...actionListGridProps}
      actionComponents={actionComponents}
      actionDefinitions={actionDefinitions}
      headers={headers}
      label={label}
      sortable={true}
    />
  );
};

interface HeldInvoicesGridDefinition extends React.FC {
  VIEW_TYPE: string;
  actionComponents: any;
}

HeldInvoicesGrid.VIEW_TYPE = 'HELD_INVOICES_GRID';
HeldInvoicesGrid.actionComponents = actionComponents;

const useInvoiceHistoryHeaders = (): Array<ICommonGridHeader> => {
  const formatMessage = useFormatMessage();
  return [...getDefaultInvoiceHeaders(formatMessage)];
};

const useHeldInvoicesActionDefinitions = () => {
  const formatMessage = useFormatMessage();
  return [
    {
      label: formatMessage(messages.gridActions.submit),
      placement: 'GRID',
      type: 'SUBMIT'
    },
    {
      label: formatMessage(messages.gridActions.viewDetails),
      placement: 'GRID',
      type: 'VIEW_DETAILS'
    },
    {
      attributes: {
        filterKey: 'q'
      },
      label: 'Filter by Name...',
      placement: 'QUERY_FILTER',
      type: 'QUERY'
    },
    {
      attributes: {
        filterKey: 'query_builder'
      },
      label: formatMessage(messages.gridActions.criteriaQuery),
      placement: 'ADVANCED_FILTER',
      type: 'QUERY_BUILDER'
    }
  ];
};

/** Returns a label for the grid with the current sandbox name included. */
const useLabel = () => {
  const tracking = useTracking();
  const formatMessage = useFormatMessage();

  return formatMessage(messages.label);
};

export default HeldInvoicesGrid;
