import React, { useContext, useEffect, useMemo, useState } from 'react';
import { find, get, isEmpty, isNaN, isNil, replace, trim } from 'lodash';

import I18nContext from '@broadleaf/admin-components/dist/common/contexts/I18nContext';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import {
  getAttribute,
  isFieldDisabled
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import type { ICommonMonetaryAmount } from '@broadleaf/admin-components/dist/types/common';
import type {
  IDerivedFieldType,
  IFormik
} from '@broadleaf/admin-components/dist/types/form';
import type {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage/index';
import cx from 'classnames';
import { Field as FormikField } from 'formik/dist/Field';

export const RmPercentageField: React.FC<MoneyFieldProps> = props => {
  const { disabled, formik, metadata, derivedValue } = props;
  const translateMode = useTranslateMode();
  const { handleBlur, setFieldTouched, setFieldValue } = formik;
  const { name, placeholder } = metadata;
  const amount = useGetAmount(metadata, formik, derivedValue);
  const { currentLocale } = useContext(I18nContext);
  const { textToDecimal, decimalToText } = usePercentageFormatters(
    currentLocale
  );
  const [draft, setDraft] = useDraft(decimalToText(amount));

  /**
   * Change handler for the input field. This function is responsible for parsing
   * out the decimal value from the input's value and updating the Formik state.
   */
  const handleChange = e => {
    const textValue = trim(e.target.value);
    const decimalValue = textToDecimal(textValue);
    if (isNaN(decimalValue) && textValue !== '-') {
      // block non-decimal inputs
      return;
    }

    setDraft(textValue);
    setFieldTouched(name, true);

    if (isEmpty(textValue) || isNil(decimalValue)) {
      // if empty, clear the whole value
      setFieldValue(name, undefined);
      return;
    }

    if (isSimpleValue(metadata)) {
      setFieldValue(name, round(decimalValue / 100, 4));
    } else {
      setFieldValue(`${name}`, round(decimalValue / 100, 4));
    }
  };

  return (
    <FieldDecorations fullWidth {...props}>
      <div className="PercentField tw-flex tw-w-full tw-items-center">
        <input
          className="motion-reduce:transition-none PercentField__input tw-tansition-colors tw-block tw-h-10 tw-w-full tw-flex-auto tw-rounded-bl-none tw-rounded-br tw-rounded-tl-none tw-rounded-tr tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-bg-clip-padding tw-px-3.5 tw-py-1.5 tw-text-sm tw-font-normal tw-leading-normal tw-text-gray-600 tw-transition focus:tw-border-green-300 focus:tw-bg-white focus:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-500 focus:tw-ring-opacity-30 rtl:tw-rounded-bl rtl:tw-rounded-br-none rtl:tw-rounded-tl rtl:tw-rounded-tr-none"
          disabled={isFieldDisabled(
            { disabled, formik: props.formik, metadata },
            translateMode
          )}
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type="text"
          value={draft}
        />
      </div>
    </FieldDecorations>
  );
};

export interface MoneyFieldProps
  extends IDerivedFieldType<ICommonMonetaryAmount | number> {
  // whether or not the field is disabled
  disabled?: boolean;
  // the formik props
  formik: IFormik;
  // the metadata for this field
  metadata: IMetadataFieldComponent;
}

function round(value: number, decimals: number) {
  return Math.round(value * 10 ** decimals) / 10 ** decimals;
}

const useDraft = initialDraft => {
  const [draft, setDraft] = useState(initialDraft);

  useEffect(() => {
    // when the initial draft value changes, we re-set the draft value
    setDraft(initialDraft);
  }, [initialDraft]);

  return [draft, setDraft];
};

const usePercentageFormatters = locale => {
  return useMemo(() => {
    const { decimalSymbol, groupSymbol } = getPercentageSymbols(locale);

    const textToDecimal = text => {
      // trim and replace decimal symbol with a valid decimal
      const formattedText = trim(
        replace(
          replace(text, stringMatching(groupSymbol), ''),
          stringMatching(decimalSymbol),
          '.'
        )
      );

      if (isEmpty(formattedText)) {
        return undefined;
      }

      return Number(formattedText);
    };

    const decimalToText = amount => {
      if (isNil(amount)) {
        return '';
      }
      const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      const formattedAmount = formatter.format(amount);
      return trim(formattedAmount);
    };

    return {
      decimalSymbol,
      groupSymbol,
      decimalToText,
      textToDecimal
    };
  }, [locale]);
};

const getPercentageSymbols = locale => {
  let formatter;
  try {
    formatter = new Intl.NumberFormat(locale, {
      style: 'percent'
    });
  } catch (e) {
    if (e instanceof RangeError) {
      formatter = new Intl.NumberFormat(locale, {
        style: 'percent'
      });
    } else {
      throw e;
    }
  }

  // use a number big enough to require a "group" symbol
  const formats = formatter.formatToParts(10000);

  // find an entry with the `decimal` type
  const decimalSymbol = get(find(formats, { type: 'decimal' }), 'value', '.');

  // find an entry with the `group` type
  const groupSymbol = get(find(formats, { type: 'group' }), 'value', ',');
  return { decimalSymbol, groupSymbol };
};

/**
 * Gets the decimal amount of money.
 *
 * @return {Number} the number amount
 */
const useGetAmount = (
  metadata: IMetadata,
  formik: IFormik,
  derivedValue: ICommonMonetaryAmount | number
): number => {
  const [values, setValues] = useState<Record<string, any>>(
    formik.initialValues
  );

  useEffect(() => {
    // have to switch to looking at the current values at the time that
    // derivedValue changes to make sure we have the right ones
    setValues(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedValue]);

  return getAmount(metadata, values, derivedValue);
};

const getAmount = (
  metadata: IMetadata,
  values: Record<string, any>,
  derivedValue: ICommonMonetaryAmount | number
): number => {
  const { name } = metadata;
  if (typeof derivedValue !== 'undefined') {
    return isSimpleValue(metadata)
      ? (derivedValue as number)
      : (derivedValue as ICommonMonetaryAmount)?.amount;
  }

  if (isSimpleValue(metadata)) {
    return get(values, name);
  }

  const defaultValue = get(metadata, 'defaultValue');
  const value = get(values, `${name}`);
  return !value && value !== 0 ? defaultValue : value;
};

const isSimpleValue = metadata => {
  return true;
};

const stringMatching = search => {
  const escapedSearch = replace(search, /[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(escapedSearch, 'g');
};

export default RmPercentageField;
