import messages from './ShipmentUtils.messages';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { RevMedShipment } from '../../../custom/types/FulfillmentTypes';

export function getTrackableCarriers(formatMessage) {
  return [
    {
      label: formatMessage(messages.carriers.ups),
      value: 'ups'
    },
    {
      label: formatMessage(messages.carriers.fedex),
      value: 'fedex'
    },
    {
      label: formatMessage(messages.carriers.usps),
      value: 'usps'
    },
    {
      label: formatMessage(messages.carriers.dhlExpress),
      value: 'dhl_express'
    }
  ];
}

//NOTE the below needs to stay in sync with fulfillment-utils.ts
export function getAllShipments(
  fulfillment: IOrderFulfillment
): RevMedShipment[] {
  const shipments = [] as RevMedShipment[];
  if (fulfillment?.shipment) {
    shipments.push(fulfillment.shipment as RevMedShipment);
  }
  if (fulfillment?.additionalShipments) {
    fulfillment.additionalShipments.forEach(shipment => {
      shipments.push(shipment as RevMedShipment);
    });
  }
  return shipments;
}

//NOTE the below needs to stay in sync with fulfillment-utils.ts
const NON_TRACKABLE_STATUSES = ['VOIDED', 'DELIVERED'];

//NOTE the below needs to stay in sync with fulfillment-utils.ts
export function isTrackableShipment(shipment: RevMedShipment) {
  return (
    shipment?.trackingNumber &&
    shipment?.carrierCode &&
    NON_TRACKABLE_STATUSES.indexOf(shipment?.shipmentStatus) === -1
  );
}

//NOTE the below needs to stay in sync with fulfillment-utils.ts
export function isPrintableShipment(shipment: RevMedShipment) {
  return (
    isTrackableShipment(shipment) &&
    (shipment?.labelPdfUrl || shipment?.labelPngUrl)
  );
}

//NOTE the below needs to stay in sync with fulfillment-utils.ts
export function showPrintLabel(fulfillment: IOrderFulfillment) {
  return (
    (fulfillment?.type == 'SHIP' &&
      fulfillment?.fulfillmentOption?.name?.startsWith('SHIPENGINE')) ||
    getAllShipments(fulfillment).some(isPrintableShipment)
  );
}

//NOTE the below needs to stay in sync with fulfillment-utils.ts
export function showUpdateTracking(fulfillment: IOrderFulfillment) {
  return getAllShipments(fulfillment).some(isTrackableShipment);
}
