/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { KeyboardEventHandler, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { capitalize, get, isArray, isNil, map } from 'lodash';
import { Creatable } from '@broadleaf/admin-components/dist/common/components/Select';

import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import { mustHaveMetadataProps } from '@broadleaf/admin-components/dist/form/utils/invariantHelpers';
import { isFieldDisabled } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import type {
  IDerivedFieldType,
  IFormik
} from '@broadleaf/admin-components/dist/types/form';
import type { IMetadataFieldComponent } from '@broadleaf/admin-components/dist/types/metadata';

import messages from './StringArrayField.messages';

const components = {
  DropdownIndicator: null
};
/**
 * Renders an input for an ARRAY_STRING field type.
 *
 * @visibleName ARRAY_STRING
 */
export const RmStringArrayField: React.FC<StringArrayFieldProps> = props => {
  const { metadata, formik, derivedValue } = props;

  // validate the metadata contains the props that we expect it to
  mustHaveMetadataProps(metadata, ['name']);

  const { name, placeholder } = metadata;
  const translateMode = useTranslateMode();
  const label = get(metadata, 'label', capitalize(name));
  let value =
    typeof derivedValue !== 'undefined'
      ? derivedValue
      : get(formik.values, name, []);

  if (!isArray(value)) {
    value = [value];
  }

  const { setFieldValue } = formik;
  useEffect(() => {
    if (!isNil(value) && !isArray(value)) {
      setFieldValue(name, undefined);
    }
  }, [name, setFieldValue, value]);

  const [inputValue, setInputValue] = React.useState('');
  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setFieldValue(name, [...(value || []), inputValue]);
        setInputValue('');
        event.preventDefault();
    }
  };
  return (
    <FieldDecorations fullWidth {...props}>
      <Creatable
        components={components}
        className="StringArrayField"
        formatCreateLabel={inputValue => (
          <FormattedMessage
            {...messages.addLabel}
            values={{ inputValue, label }}
          />
        )}
        isClearable
        isDisabled={isFieldDisabled(props, translateMode)}
        isMulti
        menuIsOpen={false}
        noOptionsMessage={() => <FormattedMessage {...messages.typePrompt} />}
        onBlur={formik.handleBlur}
        inputValue={inputValue}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        onChange={options => {
          formik.setFieldValue(
            name,
            map(options, option => option?.label)
          );
        }}
        placeholder={placeholder || ''}
        value={map(value, (value, index) => ({ value: index, label: value }))}
      />
    </FieldDecorations>
  );
};

export interface StringArrayFieldProps extends IDerivedFieldType<String[]> {
  /** whether or not this field is disabled and is unable to be edited */
  disabled?: boolean;
  /** Reference to Formik instance **/
  formik: IFormik;
  /** the metadata that is provided for rendering this field */
  metadata: IMetadataFieldComponent;
}

export default RmStringArrayField;
