import React from 'react';
import { get } from 'lodash';
import { FormattedNumber } from 'react-intl';
import { ICommonGridColumn } from '@broadleaf/admin-components/dist/types/common';
import { mustHaveMetadataProps } from '@broadleaf/admin-components/dist/form/utils/invariantHelpers';

/**
 * Renders a column for a PERCENTAGE type column.
 *
 * @visibleName PERCENTAGE
 */
export const RmPercentageColumn: React.FC<RmPercentageColumnProps> = ({
  header,
  row
}) => {
  mustHaveMetadataProps(header, ['name']);

  const amount = get(row, `${header.name}`);

  // Safely return an empty string to display.
  // Rather than attempting to convert a non-number.
  if (!amount && amount !== 0) return <span />;

  // see if there is an "override" of the currency field
  return (
    <FormattedNumber
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      value={amount}
      style="percent"
    />
  );
};

export type RmPercentageColumnProps = ICommonGridColumn & {};
export default RmPercentageColumn;
