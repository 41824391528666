/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  addLabel: {
    id: 'StringArrayField.promptLabel',
    defaultMessage: 'Add {inputValue} to {label}',
    description: 'message to add in a new value'
  },
  typePrompt: {
    id: 'StringArrayField.typePrompt',
    defaultMessage: 'Type something and press enter...',
    description: 'prompt for users to start adding values'
  }
});
