import React, { useEffect, useMemo, useState } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { Field as FormikField, FieldAttributes, FieldProps } from 'formik';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { get } from 'lodash';
import cx from 'classnames';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';

export type Option = {
  value: string;
  label: string;
  [key: string]: any;
};
export type SelectFieldProps = FieldAttributes<any> &
  ReactSelectProps<Option> & {
    name: string;
    className?: string;
    inputClassName?: string;
    formik: IFormik;
    isDisabled?: boolean;
    options: Option[];
    label: string;
  };
export const SelectField = (props: SelectFieldProps) => {
  const {
    formik,
    name,
    isDisabled,
    className = '',
    inputClassName = '',
    label,
    options
  } = props;
  const formatMessage = useFormatMessage();
  const metadata = { name, label: label };
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);

  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-1 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations fullWidth {...props} metadata={metadata}>
          <FormikField
            {...props}
            name={name}
            className={cx(inputClassName, {
              'is-invalid': touched && !!error
            })}
            isClearable={false}
            isMulti={false}
            disabled={formik.isSubmitting || isDisabled}
            options={options}
            component={SelectFormField}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const SelectFormField: React.FC<ReactSelectProps<Option> & FieldProps> = ({
  options,
  field,
  form,
  ...props
}) => {
  const [currentOptions, setCurrentOptions] = useState(options);

  // Updates the Formik values object when the selected option is changed on a uniselect
  const onChange = (selectedOption, actionMeta) => {
    if (props.onChange) {
      props.onChange(selectedOption, actionMeta);
    }
    form.setFieldValue(
      field.name,
      selectedOption ? selectedOption.value : null,
      false
    );
  };
  // Sets the value displayed by the React Select component based on the Formik values object
  const value = useMemo(() => {
    return currentOptions?.filter(
      option => field?.value == (option as Option).value
    );
  }, [currentOptions, field.value]);

  // if the options change, update the current options
  useEffect(() => {
    if (options) {
      setCurrentOptions(options);
    }
  }, [options]);
  return (
    <Select
      isDisabled={props.isDisabled}
      options={options}
      name={field.name}
      value={value}
      onChange={onChange}
      onBlur={field.onBlur}
    />
  );
};
